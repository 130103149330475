<script>import { Tabs, IconTick, IconCancel, api, Pagination, Dateformat, Alert, Formrow, Confirm, Button, IconSpinner } from "duo-kit";
import MyDetails from "./mydetails.svelte";
let team = [];
let loading = false,
    saving = false;
let userid = null;
let invoices = [],
    filtered = [],
    page = 0,
    btntotal = 0;
let credits = {},
    notify_credits = false,
    orghash;
let user = {};
let password = "";
let password_confirm = "";
let target = null;

async function load() {
  loading = true;

  try {
    team = (await api.load({
      url: `/duocms/api/team`
    })).data;
    invoices = (await api.load({
      url: `/duocms/api/team_invoices`
    })).data.reverse();
    credits = (await api.load({
      url: `/duocms/api/team/credits`
    })).data;
    notify_credits = credits.notify_credits;
    orghash = credits.orghash;
  } catch (err) {} //no-op


  loading = false;
}

async function handleSaved() {
  saving = true;

  try {
    if (password && password == password_confirm) {
      user.password = password;
    } else {
      delete user.password;
    }

    await api.save({
      url: `/duocms/api/team`,
      data: user
    });
    userid = null;
    await load();
  } catch (err) {
    console.error(err);
  }

  saving = false;
}

function getCurrency(basketdata) {
  if (!basketdata.currency) return "£";
  if (basketdata.currency == "GBP") return "£";
  if (basketdata.currency == "EUR") return "€";
  if (basketdata.currency == "USD") return "$";
  return "£";
}

async function updateNotify() {
  if (notify_credits == credits.notify_credits) return;
  console.log("updateNotify", notify_credits);
  await api.save({
    url: `/duocms/api/team/credits`,
    data: {
      notify_credits
    }
  });
  credits.notify_credits = notify_credits;
  window.sessionStorage.removeItem("credits");
}

async function deleteUser() {
  await api.remove({
    url: `/duocms/api/team/${userid}`
  });
  userid = null;
  await load();
}

load();

$: !loading && updateNotify(notify_credits);</script>

{#if orghash}
  <Alert mode="info">
    <p>If you would like other people to sign up and be linked with your team, give them the following 
      <a href="https://www.nafems.org/system/sign_up/?h={orghash}">link</a>
    </p>
    <pre>
      <code> https://www.nafems.org/system/sign_up/?h={orghash}</code>
    </pre>
  </Alert>
{/if}


<div class="teamadmin" bind:this={target} >
  <Tabs labels="Team Members|Team Invoices|Credits">
    <div slot="tab1">
      <table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Surname</th>
            <th>Email</th>
            <th style="text-align:right">Can Use Credits?</th>
          </tr>
        </thead>
        <tbody>
          {#each team as user}
          <tr class:active={userid == user.id} on:click={()=> userid = user.id}>
            <td>{user.first_name}</td>
            <td>{user.surname}</td>
            <td>{user.email}</td>
            <td style="text-align:center">{#if user.use_credits}<span class="green"><IconTick /></span>{:else}<span class="red"><IconCancel /></span>{/if}</td>
          </tr>
          {/each}
        </tbody>
      </table>

      {#if userid}
        <hr> 
        <h2>Member Details</h2>
        <MyDetails id={userid} on:saved={handleSaved} bind:user={user} tools={false} />
        <Formrow type="checkbox" label="Can Use Credits?" id="use_credits" bind:checked={user.use_credits} />
        <Formrow type="passoword" label="New Password" bind:value={password} placeholder="Please leave blank, unlesss you wish to update the password" />
        {#if password}
          <Formrow type="passoword" label="Confirm Password" bind:value={password_confirm} placeholder="Please repeat the above password" />
          {#if password != password_confirm || password.length < 8}
            <Alert mode="danger">Please ensure both passwords are the same and are 8 or more characters in length.</Alert>
          {:else if password == password_confirm && password.length >= 8}
            <Alert mode="success">Passwords Match</Alert>
          {/if}
        {/if}
        <Formrow type="checkbox" label="Send Invite Email?" id="sendinvite" bind:checked={user.sendinvite} />

        <div class="tools">
          <Confirm on:confirm={deleteUser} mode="danger" target={target}
            title="Deleting this user will remove their login to nafems.org and all transaction history. This cannot be undone. Are you sure?">
            Delete User
          </Confirm>
          <div style="flex:1" />
          <Button mode="default" disabled={saving} on:click={userid = null}>Close</Button>
          <Button mode="primary"  disabled={saving} on:click={handleSaved}>
            {#if saving}<IconSpinner /> Saving{:else} Save Changes{/if}
          </Button>
        </div>

      {/if}

    </div>
    <div slot="tab2">
      <table>
        <thead>
          <tr>
            <th>Invoice Num.</th>
            <th>Billing Name</th>
            <th>Billing Postcode</th>
            <th>Date</th>
            <th>Paid</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {#each filtered as invoice}
            <tr on:click={()=> window.open(`/duocms/api/team_invoices/${invoice.id}`)}>
              <td>{invoice.reference}</td>
              <td>{invoice.billing_name}</td>
              <td>{invoice.billing_postcode}</td>
              <td>
                <Dateformat date={invoice.created_at} format="h mm dd/MM/yyyy" />
              </td>
              <td>{#if invoice.confirmed}Yes{:else}No{/if}</td>
              <td>{getCurrency(invoice.basketdata)}{invoice.amount}</td>
            </tr>
          {/each}
        </tbody>
      </table>
      <br>
      <p>Page {page+1} of {btntotal}</p>
      <Pagination bind:data={invoices} bind:filtered={filtered} bind:page bind:btntotal />
      
    </div>
    <div slot="tab3">
        <p>Your account has <strong>{credits.credits} {credits.credits == 1 ? "Credit" : "Credits"}</strong> available.</p>
        <br>
        <Alert mode="info">
          <label>
            <input type="checkbox" bind:checked={notify_credits} />
            Notify Me By Email When Credits Are Used
          </label>
        </Alert>
      
    </div>
  </Tabs> 
</div>

<style>
  .teamadmin{
    --s-primary:#2d2e4e;
  }
  .teamadmin :global(.spopover-title){
    max-width:300px
  }
  table{
    width:100%;
  }
  th,td{
    padding:8px;
  }
  tbody > tr{
    transition:background-color 0.2s;
    border-top: 1px solid #ddd;
  }
  tbody > tr:nth-of-type(odd) {
    background-color: #f9f9f9;
  }
  tbody > tr.active{
    background-color:#2d2e4e;
    color:white;
  }
  tbody > tr:hover{
    cursor:pointer;
    background-color:#f1f1f1;
  }

  tbody > tr.active:hover{
    background-color:rgb(61, 62, 92);
  }

  .red{
    color:#c00;
  
  }
  .green{
    color:#0c0;
  }

  .tools{
    border-top:1px solid #ddd;
    padding-top:20px;
    margin-top:20px;
    display: flex;
  }


</style>