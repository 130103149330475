// Generated by CoffeeScript 2.5.1
var anchors, isEditMode, riot, showlogin;

isEditMode = window.location.search.match(/editmode=true/);

showlogin = () => {
  var loginbar;
  loginbar = document.querySelector('loginbar');
  if (loginbar && loginbar._tag) { // riot 
    if (loginbar._tag.redir) { // prevent redirects
      loginbar._tag.redir = null;
    }
    loginbar._tag.tags.loginmodal.show();
    return new Promise((resolve, reject) => {});
  }
};
import * as Sentry from "@sentry/browser";
import {component,Forum,Toaster,Tabs} from "duo-kit";
import Tracker from "./pse-tracker/index.svelte";
import TechCommunities from "./tags/technical-communities.svelte";
import NWCAbstracts from "./tags/nwc-abstracts.svelte"
import CurrencyCheck from "./tags/currency-check.svelte"
import AgendaModal from "./tags/agenda-modal.svelte"
import AgendaButton from "./tags/agenda-button.svelte"
import AgendaTime from "./tags/agenda-time.svelte"
import LuluPicker from "./resource-center/lulu-picker.svelte"
import AdhocProduct from "./tags/adhoc-product.svelte";
import ResourceCenter from "./resource-center/resource-center.svelte";
import StudentUpgrade from "./tags/student-upgrade.svelte";
import Quiz from "./tags/quiz.svelte";
import DuoMath from "./tags/duo-math.svelte";
import Test from "./tags/test.svelte";
import "./libs/training-progress.js"
import TrainingProgress from "./tags/training-progress.svelte";
import TrainingComplete from "./tags/training-complete.svelte";
import view3d from "./tags/view3d.svelte";
import MyDetails from "./tags/mydetails.svelte";
import TeamAdmin from "./tags/team-admin.svelte";
import FreeBooking from "./tags/free-booking.svelte";
import AddressHack from "./tags/address-hack.svelte"

Sentry.init({
  dsn: "https://f0df5e67449a6afbd0a13443a429604c@sentry.duodesign.co.uk/3",
  beforeSend(event) {
    if (!event.browser || event.browser.match(/bytespider/i)) return null;
  },
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/www\.nafems\.org\/duocms\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

component({component:Forum,tagname:"duo-forum",href:"/resources/css/site.css",shadow:isEditMode,attributes:["forumid"],defaults:{logintxt:"Login or Register",showlogin}})
component({component:Toaster,tagname:"duo-toaster",href:"/resources/css/site.css",shadow:isEditMode})
component({component:Tabs,tagname:"duo-tabs",href:"/resources/css/site.css",shadow:isEditMode,attributes:["labels"]})
component({component:TechCommunities,tagname:"technical-communities",href:"/resources/css/site.css",attributes:["userid"],shadow:isEditMode})
component({component:Tracker,tagname:"pse-tracker",href:"/resources/css/site.css",shadow:isEditMode,attributes:[],defaults:{showlogin}})
component({component:NWCAbstracts,tagname:"nwc-abstracts",href:"/resources/css/site.css",shadow:isEditMode})
component({component:CurrencyCheck,tagname:"currency-check",href:"/resources/css/site.css",shadow:isEditMode,attributes:["currency"],defaults:{currency:"GBP"}})
component({component:AgendaModal,tagname:"agenda-modal",href:"/resources/css/site.css",shadow:true})
component({component:AgendaButton,tagname:"agenda-button",href:"/resources/css/site.css",shadow:true,attributes:["index","dayindex"]})
component({component:AgendaTime,tagname:"agenda-time",href:"/resources/css/site.css",shadow:true,attributes:["time"]})
component({component:LuluPicker,tagname:"lulu-picker",href:"/resources/css/site.css",shadow:true,attributes:["value"]})
component({component:AdhocProduct,tagname:"adhoc-product",href:"/resources/css/site.css",shadow:true,attributes:["value"]})
component({component:ResourceCenter,tagname:"resource-center",href:"/resources/css/site.css",shadow:true,attributes:["student","category","resourcetype","accesstype","language","hide","perpage","fields","keyword","author","organisation","fromyear","toyear"]})
component({component:StudentUpgrade,tagname:"student-upgrade",href:"/resources/css/site.css",shadow:true,attributes:["value"]})
component({component:Quiz,tagname:"quiz-tag",href:"/resources/css/site.css",shadow:true,attributes:["id"]})
component({component:DuoMath,tagname:"duo-math",href:"/resources/css/site.css",shadow:true,attributes:["value","size","align"]})
component({component:Test,tagname:"error-test",href:"/resources/css/site.css",shadow:true,attributes:[]})
component({component:TrainingProgress,tagname:"training-progress",href:"/resources/css/site.css",shadow:true,attributes:["path","total","completed"]})
component({component:TrainingComplete,tagname:"training-complete",href:"/resources/css/site.css",shadow:true,attributes:["total","completed"]})
component({component:view3d,tagname:"view3d-tag",href:"/resources/css/site.css",shadow:true,attributes:["src","style"]})

component({component:MyDetails,tagname:"my-details",href:"/resources/css/site.css",shadow:true,attributes:["src","style"]})
component({component:TeamAdmin,tagname:"team-admin",href:"/resources/css/site.css",shadow:true,attributes:["src","style"]})
component({component:FreeBooking,tagname:"free-booking",href:"/resources/css/site.css",shadow:true,attributes:["src","style"]})
component({component:AddressHack,tagname:"address-hack",href:"/resources/css/site.css",shadow:true,attributes:["src","style"]})


window.riot = riot = require('riot');
require('riot-kit/lib/form');
require('riot-kit/lib/twitter');
require('./tags/training-search.tag');
require('./tags/twitter.tag');
require('./tags/mydetails.tag');
require('./tags/loginbar.tag');
require('./tags/navigation.tag');
require('./tags/migrationbar.tag');
require('./tags/teamadmin.tag');
require('./tags/bookdelegates.tag');
require('./tags/buydelegates.tag');
require('./tags/tags-input.tag');
require('./tags/mydownloads.tag');
require('./tags/openlogin.tag');
require('./tags/resource-popup.tag');
require('riot-kit/lib/alert');
require('./tags/community-checkbox.tag');
require('riot-kit/lib/modal');
require('riot-kit/lib/form');
require('riot-kit/lib/ecommerce');
require('riot-kit/lib/pagination');
require('./tags/datepopover.tag');
require('./tags/formdateinput.tag');
require('riot-kit/lib/dateformat');
require('riot-kit/lib/auth/change-password');
require('riot-kit/lib/auth/forgot-password');
require('riot-kit/lib/gmap');
require('./pse/index.coffee');
require('./resource-center/index.coffee');
require('./libs/slideshow.coffee');
require('./tags/flexipass.tag');
require('./tags/signup.tag');
require('./tags/mypassword.tag');
require('riot-kit/lib/dropdown');
require('./tags/buy.tag');

riot.mount('*');

anchors = [].slice.call(document.querySelectorAll('a[name]'));

anchors.forEach((a) => {
  if (a.name && a.name.match(/^[A-Z]{1}$/g)) {
    return a.classList.add('glossarylink');
  }
});

function maxHeight(iframeDoc){
  let body = iframeDoc.querySelector("body")
  let html = iframeDoc.documentElement
  body.style.height = "auto"
  return body.scrollHeight+20
}

// quiz frames
function frameWatch(frame){
  var iframeDoc = frame.contentDocument || frame.contentWindow.document;
  if(!iframeDoc.querySelectorAll("div").length){
    setTimeout(()=> frameWatch(frame),500) // try again if not ready
    return
  }
  let height = 0
  function handleDocumentMutation(){
    let newheight =  maxHeight(iframeDoc)
    if(height!=newheight){
      height = newheight
      console.log("updating height",newheight+"px")
      frame.style.height = height+"px"
    }
  }
  const observer = new MutationObserver(handleDocumentMutation);
  observer.observe(iframeDoc.body, {
    subtree: true,
    attributes: true,
    childList: true,
    characterData: true
  });
  window.addEventListener("resize",handleDocumentMutation)
  setTimeout(handleDocumentMutation,1000) // run once 1 second after load
}

Array.from(document.querySelectorAll('iframe[src*="quizzes"]')).forEach(frame =>{
  var iframeDoc = frame.contentDocument || frame.contentWindow.document;
  if (iframeDoc.readyState  != 'complete' ) {
    frame.addEventListener("load",(e)=> frameWatch(frame))
  }else{
    frameWatch(frame)
  }
})